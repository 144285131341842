<template>
  <main class="price-list -mt-28 bg-white pt-28">
    <div class="mx-auto max-w-7xl px-2 py-20 sm:px-6 lg:px-8">
      <div class="sm:align-center text-center sm:flex sm:flex-col">
        <h2
          class="font-dm-serif-display text-2xl font-normal tracking-wide text-papi-black-100 sm:text-4xl lg:text-6xl"
        >
          Cennik
        </h2>
      </div>
      <article id="lashes" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            STYLIZACJA RZĘS
          </p>
          <div
            v-for="lash in lashes"
            :key="lash.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ lash.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lash.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lash.priceMonthly }} {{ lash.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lash.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lash.priceMonthly2 }} {{ lash.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="makeups" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MAKIJAŻ PERMANENTNY
          </p>
          <div
            v-for="makeup in makeups"
            :key="makeup.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ makeup.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ makeup.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ makeup.priceMonthly }} {{ makeup.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ makeup.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ makeup.priceMonthly2 }} {{ makeup.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="event-makeups" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MAKIJAŻ OKOLICZNOŚCIOWY
          </p>
          <div
            v-for="eventMakeup in eventMakeups"
            :key="eventMakeup.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ eventMakeup.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ eventMakeup.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ eventMakeup.priceMonthly }}
                  {{ eventMakeup.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ eventMakeup.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ eventMakeup.priceMonthly2 }} {{ eventMakeup.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="hair-style" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            STYLIZACJA WŁOSÓW
          </p>
          <div
            v-for="hair in hairStyle"
            :key="hair.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ hair.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ hair.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ hair.priceMonthly }}
                  {{ hair.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ hair.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ hair.priceMonthly2 }} {{ hair.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="nails" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            STYLIZACJA PAZNOKCI
          </p>
          <div
            v-for="nail in nails"
            :key="nail.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ nail.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ nail.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ nail.priceMonthly }}
                  {{ nail.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ nail.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ nail.priceMonthly2 }} {{ nail.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="manicure" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MANICURE HYBRYDOWY
          </p>
          <div
            v-for="mani in manicure"
            :key="mani.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ mani.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ mani.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ mani.priceMonthly }}
                  {{ mani.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ mani.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ mani.priceMonthly2 }} {{ mani.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="pedicure" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            PEDICURE
          </p>
          <div
            v-for="pedi in pedicure"
            :key="pedi.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ pedi.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ pedi.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ pedi.priceMonthly }}
                  {{ pedi.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ pedi.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ pedi.priceMonthly2 }} {{ pedi.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="braids" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            PLECIONKI Z WŁOSÓW SYNTETYCZNYCH
          </p>
          <div
            v-for="braid in braids"
            :key="braid.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ braid.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ braid.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ braid.priceMonthly }}
                  {{ braid.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ braid.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ braid.priceMonthly2 }} {{ braid.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="face-mesotherapy" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MEZOTERAPIA IGŁOWA TWARZY
          </p>
          <div
            v-for="face in faceMesotherapy"
            :key="face.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ face.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ face.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ face.priceMonthly }}
                  {{ face.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ face.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ face.priceMonthly2 }} {{ face.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="head-mesotherapy" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MEZOTERAPIA SKÓRY GŁOWY
          </p>
          <div
            v-for="head in headMesotherapy"
            :key="head.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ head.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ head.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ head.priceMonthly }}
                  {{ head.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ head.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ head.priceMonthly2 }} {{ head.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <!-- <article id="eye-mesotherapy" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MEZOTERAPIA OKOLIC OCZU
          </p>
          <div
            v-for="eye in eyeMesotherapy"
            :key="eye.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ eye.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ eye.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ eye.priceMonthly }}
                  {{ eye.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ eye.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ eye.priceMonthly2 }} {{ eye.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article> -->

      <article id="face-clean" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            OCZYSZCZANIE TWARZY
          </p>
          <div
            v-for="face in faceClean"
            :key="face.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ face.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ face.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ face.priceMonthly }}
                  {{ face.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ face.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ face.priceMonthly2 }} {{ face.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="tissue-stimulators" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            STYMULATORY TKANKOWE/SKIN BOOSTERY
          </p>
          <div
            v-for="tissue in tissueStimulators"
            :key="tissue.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ tissue.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ tissue.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ tissue.priceMonthly }}
                  {{ tissue.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ tissue.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ tissue.priceMonthly2 }} {{ tissue.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="skin-booster" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            SKINBOOSTER
          </p>
          <div
            v-for="skin in skinBooster"
            :key="skin.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ skin.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ skin.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ skin.priceMonthly }}
                  {{ skin.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ skin.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ skin.priceMonthly2 }} {{ skin.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="lips" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MODELOWANIE UST
          </p>
          <div
            v-for="lip in lips"
            :key="lip.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ lip.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lip.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lip.priceMonthly }}
                  {{ lip.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lip.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lip.priceMonthly2 }} {{ lip.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="lipolysis" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            LIPOLIZA INIEKCYJNA
          </p>
          <div
            v-for="lipo in lipolysis"
            :key="lipo.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ lipo.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lipo.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lipo.priceMonthly }}
                  {{ lipo.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ lipo.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ lipo.priceMonthly2 }} {{ lipo.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="bodies" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            CIAŁO
          </p>
          <div
            v-for="body in bodies"
            :key="body.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ body.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ body.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ body.priceMonthly }}
                  {{ body.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ body.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ body.priceMonthly2 }} {{ body.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="peelings" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            PEELINGI CHEMICZNE
          </p>
          <div
            v-for="peeling in peelings"
            :key="peeling.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ peeling.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ peeling.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ peeling.priceMonthly }}
                  {{ peeling.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ peeling.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ peeling.priceMonthly2 }} {{ peeling.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="mesotherapy" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            MEZOTERAPIA FRAKCYJNA
          </p>
          <div
            v-for="meso in mesotherapy"
            :key="meso.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ meso.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ meso.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ meso.priceMonthly }}
                  {{ meso.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ meso.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ meso.priceMonthly2 }} {{ meso.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article id="botulinum-toxin" class="mt-8 flex justify-center">
        <div
          class="mt-6 flex w-full flex-wrap justify-center space-y-4 border border-papi-gold-200 py-12 md:w-3/4"
        >
          <p
            class="mx-1 mb-4 w-full text-center text-base text-papi-gray-300 sm:text-lg md:text-xl"
          >
            TOKSYNA BOTULINOWA
          </p>
          <div
            v-for="botulinum in botulinumToxin"
            :key="botulinum.name"
            class="w-11/12 rounded-sm bg-papi-gray-100 shadow-sm md:w-4/5"
          >
            <div
              class="flex flex-wrap items-center justify-between px-4 py-2 text-papi-black-100 sm:px-6"
            >
              <h2
                class="mb-2 w-full text-sm font-medium uppercase leading-6 sm:text-base"
              >
                {{ botulinum.title }}
              </h2>

              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ botulinum.name }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ botulinum.priceMonthly }}
                  {{ botulinum.pricePln }}
                </p>
              </div>
              <div class="flex w-full items-center justify-between">
                <h3 class="w-3/4 text-sm font-light leading-6 sm:text-base">
                  {{ botulinum.name2 }}
                </h3>
                <p class="mt-2 whitespace-nowrap text-right text-sm sm:text-lg">
                  {{ botulinum.priceMonthly2 }} {{ botulinum.pricePln2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>

<script>
const lashes = [
  {
    title: "Classic (1:1)",
    name: "Nowa aplikacja",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "Uzupełnienie",
    priceMonthly2: "170",
    pricePln2: "zł",
  },
  {
    title: "Light look (2:1, 3:1)",
    name: "Nowa aplikacja",
    priceMonthly: "220",
    pricePln: "zł",
    name2: "Uzupełnienie",
    priceMonthly2: "200",
    pricePln2: "zł",
  },
  {
    title: "Medium look (4:1, 5:1)",
    name: "Nowa aplikacja",
    priceMonthly: "240",
    pricePln: "zł",
    name2: "Uzupełnienie",
    priceMonthly2: "210",
    pricePln2: "zł",
  },
  {
    title: "Hollywood look (6:1, 8:1)",
    name: "Nowa aplikacja",
    priceMonthly: "260",
    pricePln: "zł",
    name2: "Uzupełnienie",
    priceMonthly2: "220",
    pricePln2: "zł",
  },
  {
    title: "Hyper volume (8:1, 12:1)",
    name: "Nowa aplikacja",
    priceMonthly: "270",
    pricePln: "zł",
    name2: "Uzupełnienie",
    priceMonthly2: "230",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "UZUPEŁNIENIE UWZGLĘDNIANE JEST MAX DO 3 TYGODNI.",
    priceMonthly: "",
    pricePln: "",
    name2: "MIN. 30% RZĘS PO ZDJĘCIU ODROSTÓW.",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "LAMINACJA RZĘS (PODKRĘCENIE, HENNA, ODŻYWIENIE)",
    priceMonthly: "180",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "LAMINACJA BRWI",
    priceMonthly: "160",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "HENNA BRWI (UTRZYMANIE OK. 2-3 TYG.)",
    priceMonthly: "60",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "HENNA PUDROWA (UTRZYMANIE DO 5 TYG.)",
    priceMonthly: "",
    pricePln: "",
    name2: "(Z REGULACJĄ ORAZ GEOMETRIĄ BRWI)",
    priceMonthly2: "120",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "HENNA RZĘS",
    priceMonthly: "50",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "REGULACJA BRWI",
    priceMonthly: "40",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PAKIET (REGULACJA + HENNA KLASYCZNA)",
    priceMonthly: "70",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "ZDJĘCIE RZĘS",
    priceMonthly: "50",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PRÓBA UCZULENIOWA",
    priceMonthly: "50",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WOSK WĄSIK",
    priceMonthly: "40",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DODATKOWA OKOLICA TWARZY",
    priceMonthly: "+30",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const makeups = [
  {
    title: "",
    name: "BRWI METODA OMBRE BROWS",
    priceMonthly: "1100",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "USTA (KONTUR Z WYPEŁNIENIEM)",
    priceMonthly: "1000",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KRESKA ZAGĘSZCZAJĄCA W LINII RZĘS",
    priceMonthly: "700",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KRESKA DEKORACYJNA",
    priceMonthly: "900",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KRESKA PUDROWA",
    priceMonthly: "900",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KRESKA DOLNA",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "REMOVER (1 ZABIEG)",
    priceMonthly: "150-300",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "OCIEPLENIE BRWI (1 ZABIEG)",
    priceMonthly: "350",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "*CENY ZAWIERAJĄ KOREKTĘ PO MIESIĄCU",
    name: "*ODŚWIEŻENIE KOLORU DO ROKU 50% CENY GŁÓWNEJ",
    priceMonthly: "",
    pricePln: "",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const eventMakeups = [
  {
    title: "",
    name: "MAKIJAŻ OKOLICZNOŚCIOWY",
    priceMonthly: "180-200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MAKIJAŻ PRÓBNY/ŚLUBNY",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WARSZTATY Z MAKIJAŻU /NA UŻYTEK WŁASNY 4H/",
    priceMonthly: "",
    pricePln: "",
    name2: "(KAŻDA KOLEJNA GODZINA +100 ZŁ)",
    priceMonthly2: "500",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "ŚWIĘTA (CZERWONA DATA W KALENDARZU)",
    priceMonthly: "",
    pricePln: "",
    name2: "*CENA ZAWIERA KĘPKI RZĘS.",
    priceMonthly2: "+30",
    pricePln2: "zł",
  },
];

const hairStyle = [
  {
    title: "",
    name: "KLASYCZNE FALE/LOKI",
    priceMonthly: "150-180",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PROSTOWANIE",
    priceMonthly: "100-200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const nails = [
  {
    title: "",
    name: "PRZEDŁUŻANIE PAZNOKCI",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "ŻEL/AKRYLO BEZ PRZEDŁUŻANIA",
    priceMonthly: "180",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "UZUPEŁNIENIE",
    priceMonthly: "150",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "USUWANIE ŻELU/AKRYLOŻELU",
    priceMonthly: "40",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "ZDOBIENIA",
    priceMonthly: "10-30",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MALOWANIE RĘCZNE",
    priceMonthly: "20-30",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "ONE STROKE 1 PAZNOKIEĆ",
    priceMonthly: "20",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const manicure = [
  {
    title: "",
    name: "MANICURE HYBRYDOWY",
    priceMonthly: "130",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MANICURE KOMBINOWANY (HYBRYDOWY)",
    priceMonthly: "120",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "USUWANIE LAKIERU HYBRYDOWEGO",
    priceMonthly: "30",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MANICURE Z MALOWANIEM KLASYCZNYM",
    priceMonthly: "70",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MANICURE BEZ MALOWANIA",
    priceMonthly: "60",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MANICURE JAPOŃSKI",
    priceMonthly: "50",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "NAPRAWA POJEDYNCZEGO PAZNOKCIA",
    priceMonthly: "10-20",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "BABYBOOMER/FRENCH",
    priceMonthly: "+30",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const pedicure = [
  {
    title: "",
    name: "PEDICURE HYBRYDOWY",
    priceMonthly: "",
    pricePln: "",
    name2: "(OPRACOWANIE PAZNOKCIA, PIĘTY, MALOWANIE HYBRYDOWE)",
    priceMonthly2: "150",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PÓŁ-PEDICURE HYBRYDOWY",
    priceMonthly: "",
    pricePln: "",
    name2: "(OPRACOWANIE PAZNOKCIA, MALOWANIE HYBRYDOWE)",
    priceMonthly2: "130",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PEDICURE",
    priceMonthly: "",
    pricePln: "",
    name2: "(PIĘTY, OPRACOWANIE PAZNOKCIA)",
    priceMonthly2: "90",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PEDICURE KLASYCZNY",
    priceMonthly: "",
    pricePln: "",
    name2: "(OPRACOWANIE PAZNOKCIA, PIĘTY, MALOWANIE KLASYCZNE)",
    priceMonthly2: "100",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PÓŁ-PEDICURE BEZ MALOWANIA",
    priceMonthly: "",
    pricePln: "",
    name2: "(OPRACOWANIE PAZNOKCIA)",
    priceMonthly2: "70",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PÓŁ-PEDICURE KLASYCZNY",
    priceMonthly: "",
    pricePln: "",
    name2: "(OPRACOWANIE PAZNOKCIA, MALOWANIE KLASYCZNE)",
    priceMonthly2: "70",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "PEDICURE JAPOŃSKI",
    priceMonthly: "80",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PÓŁ-PEDICURE JAPOŃSKI",
    priceMonthly: "70",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const braids = [
  {
    title: "",
    name: "JEDEN WARKOCZ BOKSERSKI",
    priceMonthly: "100",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DWA WARKOCZE BOKSERSKIE",
    priceMonthly: "140",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KUC KLASYCZNY (3 WARKOCZE)",
    priceMonthly: "150",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KUC 4-5 WARKOCZY",
    priceMonthly: "180",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WORKOCZYKI MOTYLKI",
    priceMonthly: "120",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WARKOCZ - KITKI DOLNE",
    priceMonthly: "110",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WARKOCZE Z GÓRNYMI KITKAMI",
    priceMonthly: "130",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WARKOCZYKOWE KOCZKI",
    priceMonthly: "140",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KUC Z WARKOCZY",
    priceMonthly: "160",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "WODOSPAD",
    priceMonthly: "120",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PAPI KUC NA GUMCE",
    priceMonthly: "100",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "BIŻUTERIA",
    priceMonthly: "10-40",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const faceMesotherapy = [
  {
    title: "CYTOCARE 502",
    name: "(NAWILŻENIE, REWITALIZACJA)",
    priceMonthly: "350",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "CYTOCARE 516",
    name: "(PRZECIWSTARZENIOWY)",
    priceMonthly: "350",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "CYTOCARE 532",
    name: "(ODMŁODZENIE I WYGŁADZENIE)",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "CYTOCARE C 715",
    name: "(WYPEŁNIENIE, NAWILŻENIE, LIFTING)",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "LINERASEHYDRO",
    name: "(REGENERACJA, REWITALIZACJA, NAWILŻENIE, ANTI-AGING)",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "FILORGA NCTF 135",
    name: "(PRZECIWSTARZENIOWE)",
    priceMonthly: "300",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "FILORGA NCTF 135 HA",
    name: "(REWITALIZACJA, NAWILŻENIE, POPRAWA KOLORYTU SKÓRY, POPRAWA GĘSTOŚCI, SPŁYCENIE ZMARSZCZEK)",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "NEAUVIA HYDRO DELUXE",
    name: "(NAWILŻENIE, REWITALIZACJA, STYMULACJA PRODUKCJI KOLAGENU, ANTI-AGING)",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "PRINCESS RICH",
    name: "(GŁĘBOKIE NAWILŻENIE I ODŻYWIENIE SKÓRY)",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "DERMAHEAL SR",
    name: "(TRĄDZIK, NAWILŻENIE, REWITALIZACJA)",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "DERMAHEAL SB",
    name: "(ROZŚWIETLENIE, WYGŁADZENIE)",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "DERMAHEAL HSR",
    name: "(PRZECIWSTARZENIOWE)",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "AQUASHINE BTX",
    name: "(ANTI-AGING DLA SKÓRY NA TWARZY, SZYI ORAZ DEKOLTU)",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "HYALUAL XELA 1,1%",
    name: "(PRZECIWSTARZENIOWO, REGENERACJA)",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "HYALUAL XELA 1,8%",
    name: "(POPRAWA JAKOŚCI SKÓRY OD 35 R.Ż.)",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "HYALUAL XELA 2,2%",
    name: "(REGENERACJA, LIFTING, PRZEBARWIENIA, NAWILŻENIE, SPŁYCENIE ZAMRSZCZEK PO 40 R.Ż.)",
    priceMonthly: "550",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "*NIE WSZYSTKIE ZABIEGI ZOSTAŁY UMIESZCZONE.",
    priceMonthly: "",
    pricePln: "",
    name2: "WYCENA INDYWIDUALNA.",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const headMesotherapy = [
  {
    title: "",
    name: "HAIR FILLER/HAIR CARE/RICH HAIR",
    priceMonthly: "300",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DR CYJ 1ML",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "NUCLEOFILL MEDIUM PLUS - HAIR 2ML",
    priceMonthly: "800",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

// const eyeMesotherapy = [
//   {
//     title: "",
//     name: "DERMAHEAL DARK CIRCLE SOLUTION",
//     priceMonthly: "",
//     pricePln: "",
//     name2: "(CIENIE POD OCZAMI)",
//     priceMonthly2: "300",
//     pricePln2: "zł",
//   },
//   {
//     title: "",
//     name: "DERMAHEAL EYEBAG SOLUTION",
//     priceMonthly: "",
//     pricePln: "",
//     name2: "(WORKI POD OCZAMI)",
//     priceMonthly2: "300",
//     pricePln2: "zł",
//   },
// ];

const faceClean = [
  {
    title: "",
    name: "DETOX PEEL",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const tissueStimulators = [
  {
    title: "",
    name: "PROFHILO",
    priceMonthly: "850",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "LINERASE",
    priceMonthly: "750",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "JALUPRO",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "JALUPRO HMW",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "SUNEKOS 200",
    priceMonthly: "",
    pricePln: "",
    name2: "(TWARZ + OCZY)",
    priceMonthly2: "500",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "SUNEKOS 1200",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "NUCLEOFILL STRONG",
    priceMonthly: "650",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "POLFILL STRONG 2ML",
    priceMonthly: "650",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "TWAC 2.0/3.0",
    priceMonthly: "700",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "KARISMA",
    priceMonthly: "900",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const skinBooster = [
  {
    title: "",
    name: "GŁĘBOKIE NAWILŻENIE I REWITALIZACJA",
    priceMonthly: "",
    pricePln: "",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "RESTYLANE SKINBOOSTERS VITAL",
    priceMonthly: "550",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "RESTYLANE SKINBOOSTERS VITAL LIGHT",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "FILLMED M-HA 18",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "EJAL 40",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "(TWARZ + OCZY)",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "OKOLICE OCZU",
    priceMonthly: "",
    pricePln: "",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "TWAC EYE",
    priceMonthly: "500",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "NUCLEOFILL SOFT EYES",
    priceMonthly: "650",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "SENSI EYES",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "*NIE WSZYSTKIE ZABIEGI ZOSTAŁY UMIESZCZONE.",
    priceMonthly: "",
    pricePln: "",
    name2: "WYCENA INDYWIDUALNA.",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const lips = [
  {
    title: "",
    name: "STYLAGE M 0,5 ML",
    priceMonthly: "550",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "STYLAGE M 1 ML",
    priceMonthly: "750",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "REVOLAX 0,5 ML",
    priceMonthly: "550",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "REVOLAX 1 ML",
    priceMonthly: "750",
    pricePln: "zł",
    name2: "(TWARZ + OCZY)",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "JUVEDERM ULTRA SMILE 0,55 ML",
    priceMonthly: "600",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "JUVEDERM ULTRA 3 1 ML",
    priceMonthly: "800",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const lipolysis = [
  {
    title: "",
    name: "PODBRÓDEK",
    priceMonthly: "300-450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "INNE PARTIE CIAŁA (1 AMPUŁKA)",
    priceMonthly: "400-450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const bodies = [
  {
    title: "",
    name: "(USUWANIE CELLULITU, UJĘDRNIANIE)",
    priceMonthly: "",
    pricePln: "",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "CELLUTRIX 10 ML - NA CELLULIT!",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "CELLUTRIX 20 ML (2 PARTIE)",
    priceMonthly: "350",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "CELLUTRIX 30 ML (3 PARTIE)",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "STRIMATRIX - JĘDRNE CIAŁO! 10 ML",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "STRIMATRIX 20 ML",
    priceMonthly: "350",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "STRIMATRIX 30 ML",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const peelings = [
  {
    title: "",
    name: "TCA15%-35% + MASKA",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PRX-Т33",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PQAGE EV + MASKA",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "RETRIX C",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "GLIKOLOWY + MASKA",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "PIROGRONOWY + MASKA",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MIGDAŁOWY + MASKA",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "SALICYLOWY + MASKA",
    priceMonthly: "200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "SET ACNEOUT PEEL (LECZENIE TRĄDZIKU)",
    priceMonthly: "250",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const mesotherapy = [
  {
    title: "",
    name: "DERMAPEN + AMPUŁKA",
    priceMonthly: "300-400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DERMAPEN + NCTF 135/135 HA",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DERMAPEN + BIOAGEPEEL",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "DERMAPEN + BIOREPEEL",
    priceMonthly: "400",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

const botulinumToxin = [
  {
    title: "",
    name: "1 OKOLICA",
    priceMonthly: "450",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "2 OKOLICE",
    priceMonthly: "850",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "3 OKOLICE",
    priceMonthly: "1200",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "BRUKSIZM (LECZENIE MIĘŚNI ŻWACZY)",
    priceMonthly: "900",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
  {
    title: "",
    name: "MARMURKOWA BRODA",
    priceMonthly: "",
    pricePln: "",
    name2: "(USUWANIE ZMARSZCZEK NA BRODZIE)",
    priceMonthly2: "500",
    pricePln2: "zł",
  },
  {
    title: "",
    name: "ZMARSZCZKI KRÓLICZE NOSA",
    priceMonthly: "200-300",
    pricePln: "zł",
    name2: "",
    priceMonthly2: "",
    pricePln2: "",
  },
];

export default {
  name: "PriceList",

  setup() {
    return {
      lashes,
      makeups,
      eventMakeups,
      hairStyle,
      nails,
      manicure,
      pedicure,
      braids,
      faceMesotherapy,
      headMesotherapy,
      // eyeMesotherapy,
      faceClean,
      tissueStimulators,
      skinBooster,
      lips,
      lipolysis,
      bodies,
      peelings,
      mesotherapy,
      botulinumToxin,
    };
  },
};
</script>
